<template>
  <div v-if="workoutSession.exercises.length" class="list-group list-group-flush">
    <WorkoutExerciseListItem
      @select="selectExercise($event)"
      v-for="(exercise, exerciseIndex) in workoutSession.exercises"
      :exercise="exercise"
      :key="`key-${exerciseIndex}-${exercise.exerciseId}`"
      class="list-group-item"
    ></WorkoutExerciseListItem>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'WorkoutExerciseList',
  components: {
    WorkoutExerciseListItem: () => import('@/components/WorkoutExerciseListItem'),
  },
  computed: {
    ...mapState('workout', ['workoutSession']),
  },
  methods: {
    selectExercise(exercise) {
      if (exercise.type !== 'section') {
        this.$emit('select', exercise.exerciseId);
      }
    },
  },
};
</script>
